.seaarch_cate_heading {
  background: var(--malon-color);
  color: white;
  padding: 10px;
  text-transform: capitalize;
  margin-bottom: 20px;
  position: relative;
}

.seaarch_cate_heading::before {
  content: " ";
  width: 10px;
  height: 10px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 20px;
}

.search_cate_container.active .seaarch_cate_heading::before {
  content: "";
  transform: rotate(135deg);
}

.search_cate_container .search_cate_content {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.search_cate_container ul li:last-child {
  border-bottom: 0 !important;
}
.search_cate_container ul li {
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0 !important;
}

.search_cate_container ul li svg {
  max-width: 10px;
  margin-right: 15px;
  color: var(--orange-color);
}

.search_cate_container.active .search_cate_content {
  height: 100%;
}

.filter_btn {
  margin: 0 10px;
  min-width: 100px;
  position: relative;
  color: inherit;
  background: none;
  border: 1px solid;
}

.filter_btn::before {
  content: " ";
  width: 5px;
  height: 5px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.filter_btn.active::before {
  transform: rotate(135deg);
}

.filter_sidebar {
  width: 100%;
  transition: 0.25s;
  display: none;
}

.filter_sidebar.active {
  display: block !important;
}

.search_sortBy {
  max-width: 200px;
  background: inherit;
  color: inherit;
}

.search_result {
  align-items: center;
}

.color_black {
  color: var(--black-color);
}
.color_black:focus {
  color: var(--black-color);
}
