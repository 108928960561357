.footer_container {
  display: flex;
  padding-top: 10px 20px;
  border-top: 1px solid var(--orange-color);
}
.footer_left {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.footer_center {
  flex: 3;
  display: flex;
  padding: 10px;
}

.footer_right {
  flex: 1;
  padding: 10px;
}

.footer_logo {
  font-weight: 800;
}

.footer_desc {
  text-align: justify;
  margin: 10px 30px 10px 0;
  font-size: 14px;
}

.footer_social {
  display: flex;
}

.footer_social_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.footer_center_menu {
  margin-bottom: 10px;
  font-size: 16px;
}

.footer_center_list {
}

.footer_center_listitem {
  width: 50%;
  margin-bottom: 5px;
  font-size: 14px;
}

.footer_center_listitem:hover {
  color: var(--orange-color);
  transition: all 0.5s ease-in-out;
}

.footer_contacts {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.footer_contacts i {
  margin-right: 10px;
}

.footor_contact_payments {
  width: 50%;
}

@media (max-width: 992px) {
  .footer_container {
    flex-direction: column;
  }
}

.footer_black_top {
  position: relative;
  width: 100%;
  height: 100px;
  background: #000;
  margin-top: -2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer_black_top img {
  height: 60px;
}

.footer_malon_top::after {
  position: absolute;
}

.footer_orange_top {
  position: relative;
  width: 100%;
  height: 125px;
  background: var(--orange-color);
  margin-top: -2px;
}

.bg_malon {
  background: var(--malon-color) !important;
  color: white;
  margin-top: -2px;
}

.bg_black {
  background: #000 !important;
}

.curve_color {
  display: flex;
  flex-direction: column;
}
.wave_img {
  width: 100%;
  margin-top: -1px;
}

@media (max-width: 992px) {
  .footer_black_top img {
    height: 30px;
    margin-right: 10px;
  }
  .footer_orange_top {
    height: 150px;
  }
  .footer_black_top {
    height: 60px;
    gap: 10px;
    padding: 2px 0;
    overflow: auto;
    justify-content: flex-start;
  }
  .footer_black_top::-webkit-scrollbar {
    display: none;
  }
  .footer_left {
    padding: 10px;
  }
  .footer_desc {
    padding: 20px;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    margin: 10px 20px 20px 20px;
  }

  .footer_connect {
    display: none;
  }

  .footer_social {
    justify-content: center;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding: 10px;
  }
  .footer_center {
    flex-direction: column;
  }
  .footer_center_list {
    margin-left: 20px;
  }
  .footer_center_listitem {
    width: auto;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
