.carousel-container {
  margin: 50px 0 100px 0;
}

.carousel_item {
  display: flex;
  margin-right: 30px;
  align-items: center;
  flex-direction: column;
}

.carousel_profile_image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

.downloadapp_title {
  text-transform: capitalize;
  font-size: 50px;
  padding-bottom: 20px;
}

.downloadapp_section {
  display: flex;
  padding: 10px 80px;
}

.downloadapp_left {
  flex: 1;
  padding: 5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.downloadapp_section_text {
  padding-bottom: 30px;
}

.downloadapp_img {
  display: flex;
  justify-content: space-between;
}

.downloadapp_img img {
  width: 20vw;
  margin-right: 5px;
  cursor: pointer;
}

.downloadapp_right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.downloadapp_small_img {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 200px;
}

@media (max-width: 992px) {
  .downloadapp_section {
    flex-direction: column;
    padding: 10px 10px;
    margin: 0;
    height: 400px;
    overflow: hidden;
    border-bottom: 5px solid var(--malon-color);
  }
  .carousel_profile_image {
    width: 150px;
    height: 150px;
  }
  .downloadapp_left {
    padding: 10px;
  }
  .downloadapp_img {
    justify-content: start;
  }

  .downloadapp_title {
    line-height: 40px;
    font-size: 30px;
    padding-bottom: 10px;
  }

  .downloadapp_section_text {
    padding-bottom: 15px;
  }

  .downloadapp_img img {
    width: 140px;
    margin-right: 10px;
  }

  .downloadapp_right img {
    width: 150px;
  }
  .spad {
    padding: 10px 0;
  }
}

.CategoryListing_section {
  display: flex;
}
