.list-group-item {
  background: none;
  color: inherit !important;
  margin-bottom: 10px;
  border: 1px solid rgba(99, 91, 91, 0.2);
}

.d-grid .search-btn1 {
  padding: 10px 30px;
}

.card {
  background: none;
  border: 1px solid rgba(99, 91, 91, 0.2);
}
/* .btn {
  color: var(--white-color);
  margin: 0 5px;
} */
.btn:hover {
  border: 0;
  background: var(--malon-color);
  color: var(--white-color);
}
.btn:focus-visible {
  outline: none;
}

.btn-primary:active {
  color: #fff;
  background-color: var(--orange-color);
  border-color: var(--orange-color);
}

.btn-primary:focus {
  color: #fff;
  background-color: var(--orange-color);
  border-color: var(--orange-color);
}
